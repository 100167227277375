import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import classNames from "classnames";

import PaymentOptionsFooter from "../../containers/PaymentOptionsFooter";
import { prepaidPurchaseInvalid, getTaxDisclaimer, getShowEuRightToCancel } from "../../redux/modules/paymentOptions";
import { isCpfValid, isDateValid, isStringNullOrWhiteSpace } from "../../utils/validators";
import EuRightToCancel from "./../EuRightToCancel";
import Tooltip from "../../components/Tooltip";

const MAX_NAME_FIELD_LENGTH = 48;

class Prepaid extends React.Component {
  state = {
    prepaidCardInput: "",
    buyerCpfInput: null,
    buyerDateOfBirthInput: null,
    buyerFirstNameInput: null,
    buyerLastNameInput: null,
    attemptedPurchase: false,
    canStartPurchase: true,
    formErrors: {
      buyerFirstNameError: false,
      buyerLastNameError: false,
      buyerCpfError: false,
      buyerDateOfBirthError: false
    },
    displayTooltip: false
  };

  onPrepaidCodeChange = (event) => {
    this.setState({
      prepaidCardInput: event.target.value
    });
  };

  onBuyerCpfChange = (event) => {
    this.setState({
      buyerCpfInput: event.target.value
    });
  };

  onBuyerFirstNameChange = (event) => {
    this.setState({
      buyerFirstNameInput: event.target.value
    });
  };

  onBuyerLastNameChange = (event) => {
    this.setState({
      buyerLastNameInput: event.target.value
    });
  };

  onBuyerDateOfBirthChange = (event) => {
    this.setState({
      buyerDateOfBirthInput: event.target.value
    });
  };

  setCanStartPurchase = (canStartPurchase = false) => {
    this.setState({ canStartPurchase });
  };

  onPrepaidCodeSubmit = () => {
    this.setState({ attemptedPurchase: true });

    if (!this.state.canStartPurchase) {
      return;
    }

    this.props.confirmPricePointPrepaidPurchase(
      this.props.sessionToken,
      this.state.prepaidCardInput,
      this.state.buyerCpfInput,
      this.state.buyerDateOfBirthInput,
      this.state.buyerFirstNameInput,
      this.state.buyerLastNameInput
    );
  };

  isSubmitDisabled = () => {
    if (this.props.showEuRightToCancel && !this.state.canStartPurchase) {
      return true;
    }

    const { buyerFirstNameInput, buyerLastNameInput, buyerCpfInput, buyerDateOfBirthInput } = this.state;
    if (
      this.isCpfForm() &&
      (isStringNullOrWhiteSpace(buyerFirstNameInput) ||
        isStringNullOrWhiteSpace(buyerLastNameInput) ||
        isStringNullOrWhiteSpace(buyerCpfInput) ||
        isStringNullOrWhiteSpace(buyerDateOfBirthInput))
    ) {
      return true;
    }

    if (Object.values(this.state.formErrors).some((error) => error)) {
      return true;
    }

    return isStringNullOrWhiteSpace(this.state.prepaidCardInput);
  };

  handleTooltipVisibilityChange = (isVisible) => {
    if (this.isSubmitDisabled()) {
      this.setState({ displayTooltip: isVisible });
    }
  };

  validateFirstName = (formErrors = this.state.formErrors) => {
    formErrors.buyerFirstNameError = false;
    if (isStringNullOrWhiteSpace(this.state.buyerFirstNameInput)) {
      formErrors.buyerFirstNameError = true;
    }
    this.setState({ formErrors });
  };

  validateLastName = (formErrors = this.state.formErrors) => {
    formErrors.buyerLastNameError = false;
    if (isStringNullOrWhiteSpace(this.state.buyerLastNameInput)) {
      formErrors.buyerLastNameError = true;
    }
    this.setState({ formErrors });
  };

  validateCpf = (formErrors = this.state.formErrors) => {
    formErrors.buyerCpfError = false;
    if (!isCpfValid(this.state.buyerCpfInput)) {
      formErrors.buyerCpfError = true;
    }
    this.setState({ formErrors });
  };

  validateDoB = (formErrors = this.state.formErrors) => {
    formErrors.buyerDateOfBirthError = false;
    if (!isDateValid(this.state.buyerDateOfBirthInput)) {
      formErrors.buyerDateOfBirthError = true;
    }
    this.setState({ formErrors });
  };

  isCpfForm = () => {
    const countryCode = this.props.country.code3 || "";
    return countryCode.toUpperCase() === "BRA" && this.props.platform.toUpperCase() === "BR1";
  };

  getErrorMessage = (code, invalidReasonCode) => {
    const unknownError = "prepaid-failure-v2-UNKNOWN";
    switch (code) {
      case "NOTFOUND":
        return `prepaid-failure-v2-${code}`;
      case "VELOCITY":
      case "DEACTIVATED":
      case "REDEEMED":
      case "EXPIRED":
        return `prepaid-failure-${code}`;
      case "INVALID":
        switch (invalidReasonCode) {
          case "AMOUNT_OR_CURRENCY":
          case "CPF":
          case "DATE_OF_BIRTH":
          case "REGION":
          case "COUNTRY":
          case "FIRST_NAME":
          case "LAST_NAME":
          case "AGE":
            return `prepaid-failure-${code}_${invalidReasonCode}`;
          default:
            return unknownError;
        }
      case "UNKNOWN":
      default:
        return unknownError;
    }
  };

  renderCpfForm = () => {
    const { formatMessage } = this.context.intl;
    const dobPlaceholder = formatMessage({
      id: "date_of_birth_placeholder",
      defaultMessage: "dd/mm/yyyy"
    });
    const firstNamePlaceholder = formatMessage({
      id: "first_name_placeholder",
      defaultMessage: "First name"
    });
    const lastNamePlaceholder = formatMessage({
      id: "last_name_placeholder",
      defaultMessage: "Last name"
    });
    const { formErrors } = this.state;

    return (
      <div className="cpf-form">
        <div className="form-row">
          <div className="form-group">
            <h4>
              <FormattedMessage id="first_name" defaultMessage="First Name" />
            </h4>
            <input
              className={classNames("input", { formError: formErrors.buyerFirstNameError })}
              type="text"
              value={this.state.buyerFirstNameInput}
              onChange={this.onBuyerFirstNameChange}
              maxLength={MAX_NAME_FIELD_LENGTH}
              placeholder={firstNamePlaceholder}
              onBlur={() => this.validateFirstName()}
            />
            {formErrors.buyerFirstNameError && <div className="error-text">Invalid First Name</div>}
          </div>
          <div className="form-group">
            <h4>
              <FormattedMessage id="last_name" defaultMessage="Last Name" />
            </h4>
            <input
              className={classNames("input", { formError: formErrors.buyerLastNameError })}
              type="text"
              value={this.state.buyerLastNameInput}
              onChange={this.onBuyerLastNameChange}
              maxLength={MAX_NAME_FIELD_LENGTH}
              placeholder={lastNamePlaceholder}
              onBlur={() => this.validateLastName()}
            />
            {formErrors.buyerLastNameError && <div className="error-text">Invalid Last Name</div>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <h4>
              <FormattedMessage id="cpf" defaultMessage="CPF" />
            </h4>
            <InputMask
              className={classNames("input", { formError: formErrors.buyerCpfError })}
              type="text"
              mask="999.999.999-99"
              formatChars={{ 9: "[0-9]" }}
              alwaysShowMask={false}
              placeholder="999.999.999"
              value={this.state.buyerCpfInput}
              onChange={this.onBuyerCpfChange}
              onBlur={() => this.validateCpf()}
            />
            {formErrors.buyerCpfError && <div className="error-text">Invalid CPF</div>}
          </div>

          <div className="form-group">
            <h4>
              <FormattedMessage id="date_of_birth" defaultMessage="Date of Birth" />
            </h4>
            <InputMask
              className={classNames("input", { formError: formErrors.buyerDateOfBirthError })}
              type="text"
              mask={dobPlaceholder}
              formatChars={{
                a: "[0-9]",
                d: "[0-9]",
                m: "[0-9]",
                y: "[0-9]"
              }}
              alwaysShowMask={false}
              placeholder={dobPlaceholder}
              value={this.state.buyerDateOfBirthInput}
              onChange={this.onBuyerDateOfBirthChange}
              onBlur={() => this.validateDoB()}
            />
            {formErrors.buyerDateOfBirthError && <div className="error-text">Invalid Date of Birth</div>}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { prepaidFailureCode, prepaidInvalidReasonCode, clearPrepaidResults, intl, showEuRightToCancel } = this.props;
    const showTaxDisclaimer = connect(getTaxDisclaimer);

    const contentCodeLink = (
      <a
        href={this.context.intl.formatMessage({
          id: "redeem_link",
          defaultMessage: "http://redeem.riotgames.com"
        })}
        target="_blank"
        rel="noopener noreferrer"
        className="link text-highlight"
      >
        <span>
          <FormattedMessage id="redeem_link" defaultMessage="http://redeem.riotgames.com." />
        </span>
      </a>
    );

    const codePlaceholderMessage = intl.formatMessage({ id: "input.placeholder", defaultMessage: "Enter Code" });
    const supportErrorLink = intl.formatMessage({ id: "support_error_link" });
    const prepaidMessage =
      "If you have a prepaid card, Riot PIN, or other code, please enter the code below and press Submit. If you have a content code, please redeem that at ";

    return (
      <div className={`prepaid-code ${this.isCpfForm() ? "cpf" : ""}`}>
        <h2>
          <FormattedMessage id="redeem_code" defaultMessage="Redeem a Code" />
        </h2>
        <p>
          <FormattedMessage id="prepaid_message_v2" defaultMessage={prepaidMessage} />
          {contentCodeLink}
        </p>
        <div className="prepaid-card">
          <div className="code-row">
            <input
              className={classNames("input", { codeError: prepaidFailureCode })}
              type="text"
              value={this.state.prepaidCardInput}
              onChange={this.onPrepaidCodeChange}
              maxLength="50"
              placeholder={codePlaceholderMessage}
            />
            {prepaidFailureCode && (
              <div className="error-text">
                <FormattedHTMLMessage
                  id={this.getErrorMessage(prepaidFailureCode, prepaidInvalidReasonCode)}
                  defaultMessage="Unknown error."
                  values={{
                    link: supportErrorLink
                  }}
                />
              </div>
            )}
          </div>
          {this.isCpfForm() && this.renderCpfForm()}
          {showEuRightToCancel ? (
            <EuRightToCancel
              setCanStartPurchase={this.setCanStartPurchase}
              attemptedPurchase={this.state.attemptedPurchase}
            />
          ) : (
            <PaymentOptionsFooter
              virtualCurrency={this.props.game === "lol" ? "RP" : undefined}
              showTaxDisclaimer={showTaxDisclaimer}
              isDirectPurchase={false}
            />
          )}
          <div className="buttons">
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.context.router.history.goBack();
                clearPrepaidResults();
              }}
            >
              <FormattedMessage id="Back" defaultMessage="Back" />
            </button>
            <Tooltip
              placement="right"
              trigger="hover"
              showArrow={true}
              onVisibilityChange={this.handleTooltipVisibilityChange}
              tooltipShown={this.state.displayTooltip}
              tooltipBody={<FormattedMessage id="prepaid_terms" defaultMessage="Please enter all fields to redeem." />}
            >
              <button className="btn btn-default" onClick={this.onPrepaidCodeSubmit} disabled={this.isSubmitDisabled()}>
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

Prepaid.contextTypes = {
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

Prepaid.propTypes = {
  prepaidFailureCode: PropTypes.string,
  prepaidInvalidReasonCode: PropTypes.string,
  clearPrepaidResults: PropTypes.func.isRequired,
  confirmPricePointPrepaidPurchase: PropTypes.func.isRequired,
  prepaidPurchaseInvalid: PropTypes.func.isRequired,
  sessionToken: PropTypes.string.isRequired,
  country: PropTypes.object.isRequired,
  game: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  showEuRightToCancel: getShowEuRightToCancel(state)
});

export default injectIntl(connect(mapStateToProps, { prepaidPurchaseInvalid })(Prepaid));
