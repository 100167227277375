import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getSessionIsNewFlow } from "../redux/modules/session";

class CountryChangeList extends React.Component {
  onChangeCountry = (country) => {
    this.props.onChangeCountry(country);
  };

  render() {
    const { currentCountry, onModalOpen, isNewFlow } = this.props;
    return (
      <div className="country-change">
        <FormattedMessage id="currently_showing_options" defaultMessage="Currently showing payment options for" />
        {isNewFlow ? (
          <a href="#/" className="link country-name" onClick={onModalOpen}>
            {currentCountry.name}
          </a>
        ) : (
          <>
            <span className="country-name">{currentCountry.name}</span>
            <br />
            <a href="#/" className="link text-highlight" onClick={onModalOpen}>
              <FormattedMessage id="change" defaultMessage="[Change]" />
            </a>
          </>
        )}
      </div>
    );
  }
}

CountryChangeList.propTypes = {
  currentCountry: PropTypes.object.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state)
});

export default connect(mapStateToProps)(CountryChangeList);
